import React, { createContext, ReactElement, useContext } from "react";
import { useYarnPackTemplates } from "../hooks/useYarnPackTemplates"
import YarnPack from "../model/YarnPack";
import { useReducer } from "react";

let yarnPackTemplates: YarnPack[];
const initialYarnPacksContextState: YarnPack = {
    id: "initial", name: "Slava Ukraini", yarns: ["#0057b7", "#ffd700", "#FF0000",
        "#A0522D",
        "#0A3F7B",
        "#FFFFFF",
        "#C6A54D",
        "#BFEFFF",
        "#1A4876",]
}

type ReducerAction = { type: `loadYarnPack`, packId: string }
    | { type: `setYarn`, index: number, color: string }
    | { type: `unshiftYarn`, color: string }
    | { type: `pushYarn`, color: string }
    | { type: `shiftYarn`, }
    | { type: `popYarn`, }

function reducer(state: YarnPack, action: ReducerAction): YarnPack {
    switch (action.type) {
        case `loadYarnPack`: {
            const template = yarnPackTemplates.find(p => p.id === action.packId)
            if (!template) {
                console.warn(`Could not find a YarnPackTemplate with id ${action.packId}.`)
                return state
            }

            return { ...template, yarns: [...template.yarns] }
        }
        case `setYarn`:
        case `unshiftYarn`:
        case `pushYarn`: {
            if (!(/^#[0-9A-F]{6}$/i.test(action.color)))
                throw new Error(`Yarn color must be a hex. Supplied color string: ${action.color}`)
            const yarns = [...state.yarns];
            switch (action.type) {
                case `setYarn`: { yarns[action.index] = action.color; break; }
                case `unshiftYarn`: { yarns.unshift(action.color); break; }
                case `pushYarn`: { yarns.push(action.color); break; }
            }
            console.log(`### ${yarns}`)
            return { ...state, yarns }
        }
        case `shiftYarn`:
        case `popYarn`: {
            const yarns = [...state.yarns];
            switch (action.type) {
                case `shiftYarn`: { yarns.shift(); break; }
                case `popYarn`: { yarns.pop(); break; }
            }
            return { ...state, yarns }
        }
    }
}

export const useYarnPacksContext = (initialState: YarnPack) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    // TODO: I don't think designTemplates should be loaded here.
    yarnPackTemplates = useYarnPackTemplates();

    return {
        state,
        loadYarnPackFromTemplate(packId: string) { dispatch({ type: `loadYarnPack`, packId }) },
        getPack(packId: string): YarnPack | undefined { return yarnPackTemplates.find(y => y.id === packId); },
        setYarn(index: number, color: string) { dispatch({ type: `setYarn`, index, color }) },
        unshiftYarn(color: string) { dispatch({ type: `unshiftYarn`, color }) },
        pushYarn(color: string) { dispatch({ type: `pushYarn`, color }) },
        shiftYarn() { dispatch({ type: `shiftYarn` }) },
        popYarn() { dispatch({ type: `popYarn` }) },
    }
}

export const YarnPacksContext = createContext<ReturnType<typeof useYarnPacksContext>>({
    state: initialYarnPacksContextState,
    loadYarnPackFromTemplate: (packId: string) => { },
    getPack: (packId: string) => undefined,
    setYarn: (index: number, yarn: string) => { },
    unshiftYarn: (yarn: string) => { },
    pushYarn: (yarn: string) => { },
    shiftYarn: () => { },
    popYarn: () => { },
});

export const YarnPacksProvider = ({ children }: { children?: ReactElement | ReactElement[] }): ReactElement => {
    return (
        <YarnPacksContext.Provider value={useYarnPacksContext(initialYarnPacksContextState)}>
            {children}
        </YarnPacksContext.Provider>
    )
}

//TODO: the below could also be in a separate hook file.
type UseYarnPacks =
    {
        yarnPackTemplates: YarnPack[],
        yarnPack: YarnPack,
        loadYarnPackFromTemplate: (packId: string) => void,
        getPack: (packId: string) => YarnPack | undefined,
        setYarn: (index: number, yarn: string) => void,
        unshiftYarn: (yarn: string) => void,
        pushYarn: (yarn: string) => void,
        shiftYarn: () => void,
        popYarn: () => void,
    }

// https://dev.to/darksmile92/js-use-spread-to-exclude-properties-1km9
export const useYarnPacks = (): UseYarnPacks => {
    // useContext returns an object. We assign `state` to a new const `yarnPack`. All other properties are spread into `context`.
    // effectively, `context` now excludes `state`. That's useful for when you want to exclude properties: just assign them to variables that you don't return.
    const { state: yarnPack, ...context } = useContext(YarnPacksContext);
    return {
        yarnPackTemplates,
        yarnPack,
        ...context
    };
}