import { FriendshipBraceletPreview } from "../components";
import ButtonGrid from "../components/ButtonGrid";
import { useDesign } from "../contexts/DesignContext";
import { useYarnPacks } from "../contexts/YarnPacksContext";
import YarnPack from "../model/YarnPack";
import { AppFragmentProps } from "./AppFragment";

export default function YarnPage({ className }: AppFragmentProps) {
    const { yarnPackTemplates, yarnPack, loadYarnPackFromTemplate } = useYarnPacks();
    const { design } = useDesign();

    return (
        <ButtonGrid<YarnPack>
            items={yarnPackTemplates}
            activeItem={yarnPackTemplates.find(t => t.id === yarnPack.id)}
            onClick={d => loadYarnPackFromTemplate(d.id)}
            gridClassName={className}
        >
            {(y: YarnPack) => <FriendshipBraceletPreview className="croppedToButton"
                strandsIn={design.yarnPattern.map(yarnRef => y.yarns[yarnRef % y.yarns.length])}
                knots={design.knots}
                firstStrandIsTieable={design.firstStrandIsTieable}
                length={20} />}
        </ButtonGrid>
    )
}