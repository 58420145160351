import { useDesign } from "../contexts/DesignContext";
import { useExportDesignTemplates } from "../hooks/useDesignTemplates";
import { AppFragmentProps } from "./AppFragment";

export default function WeavePage({ className }: AppFragmentProps) {
    const { design } = useDesign();
    return (
        <div className={`${className}`}>
            <textarea style={{ width: "100%", height: "100%" }}>
                {useExportDesignTemplates().toJSON(design)}
            </textarea>
        </div>
    )
}