import { createContext, useContext, useReducer, ReactElement } from "react"
import { useDesignTemplates } from "../hooks/useDesignTemplates";
import Design from "../model/Design";
import { KnotType } from '../model/Knot';

let designTemplates: Design[];
const initialDesignState: Design = new Design(
    "Stripes",
    [0, 1, 2, 3, 3, 2, 1, 0],
    false, [
    [KnotType.FF, KnotType.FF, KnotType.FF],
    [KnotType.FF, KnotType.FF, KnotType.FF, KnotType.FF]
]);

type ReducerAction = { type: `loadDesignTemplates`, designId: string }
    | { type: `setYarnRef`, index: number, yarnRef: number }
    | { type: `switchYarnRef`, oldYarnRef: number, newYarnRef: number | undefined }
    | { type: `unshiftYarnPattern`, yarnRef: number }
    | { type: `pushYarnPattern`, yarnRef: number }
    | { type: `shiftYarnPattern` }
    | { type: `popYarnPattern` }
    | { type: `setKnot`, rowIndex: number, knotIndex: number, knotType: KnotType | undefined }
    | { type: `clearEmptyTrailingRows` }

function reducer(state: Design, action: ReducerAction): Design {
    switch (action.type) {
        case `loadDesignTemplates`: {
            const template = designTemplates.find(t => t.id === action.designId);
            if (!template) {
                console.warn(`Could not find a Design with id ${action.designId} on DesignTemplates.`);
                return state;
            }
            const loadedDesign = new Design(template.name, [...template.yarnPattern], template.firstStrandIsTieable, template.knots.map(row => [...row]))
            loadedDesign.id = template.id;
            return loadedDesign;
        }
        case `setYarnRef`:
            return { ...state, yarnPattern: state.yarnPattern.splice(action.index, 1) }
        case `switchYarnRef`:
            const indices: number[] = state.yarnPattern.reduce((acc: number[], el: number, i: number) => el === action.oldYarnRef ? [...acc, i] : acc, [])
            let newYarnPattern = [...state.yarnPattern]
            indices.forEach(index => {
                if (action.newYarnRef)
                    newYarnPattern.splice(index, 1, action.newYarnRef)
                else newYarnPattern.splice(index, 1)
            })
            return { ...state, yarnPattern: newYarnPattern }
        case `unshiftYarnPattern`: {
            // unshift the yarns
            let newYarnPattern = [action.yarnRef, ...state.yarnPattern]
            // unshift all knots
            let newKnots = state.knots.map(row => [...row]);
            newKnots.forEach((row, index) => {
                if (index % 2 === 0 === !state.firstStrandIsTieable)
                    row.unshift(undefined)
            })

            return {
                ...state,
                yarnPattern: newYarnPattern,
                knots: newKnots,
                firstStrandIsTieable: !state.firstStrandIsTieable // toggle firstStrandIsTieable
            }
        }
        case `pushYarnPattern`: {
            return { ...state, yarnPattern: [...state?.yarnPattern, action.yarnRef] }
        }
        case `shiftYarnPattern`: {
            // shift the yarns
            const newYarnPattern = [...state.yarnPattern];
            newYarnPattern.shift();
            // shift all the knots
            let newKnots = state.knots.map(row => [...row]);
            newKnots.forEach((row, index) => {
                if (index % 2 === 0 === state.firstStrandIsTieable)
                    row.shift()
            })

            return {
                ...state,
                yarnPattern: newYarnPattern,
                knots: newKnots,
                firstStrandIsTieable: !state.firstStrandIsTieable // toggle firstStrandIsTieable
            }
        }
        case `popYarnPattern`: {
            // pop the yarns
            const newYarnPattern = [...state.yarnPattern];
            newYarnPattern.pop();

            // pop those knots that are affected
            const newKnots = [...state.knots.map(row => [...row])];
            newKnots.forEach((row, index) => {
                // calculate the rightStrandPosition of the rightmost knot.
                const rightStrandIndex = (row.length * 2) + (index % 2 === 0 === state.firstStrandIsTieable ? -1 : 0)
                console.log(rightStrandIndex);
                // if that is higher than the newYarnPattern length, then pop a copy of this row.
                if (rightStrandIndex >= newYarnPattern.length) row.pop();
            })

            return {
                ...state,
                yarnPattern: newYarnPattern,
                knots: newKnots,
            }
        }
        case `setKnot`: {
            const newKnots = [...state.knots.map(row => [...row])];
            if (newKnots[action.rowIndex] === undefined) newKnots[action.rowIndex] = [];
            newKnots[action.rowIndex][action.knotIndex] = action.knotType
            return { ...state, knots: newKnots }
        }

        case `clearEmptyTrailingRows`: {
            const newKnots = [...state.knots.map(row => [...row])];
            while (!newKnots.at(-1)?.some(k => k !== undefined)) newKnots.pop();
            return { ...state, knots: newKnots }
        }
    }
}

export const useDesignContext = (initialState: Design) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    // TODO: I don't think designTemplates should be loaded here.
    designTemplates = useDesignTemplates();

    function findKnot(rowIndex: number, leftStrandIndex: number): KnotType | undefined {
        return state?.knots[rowIndex]?.[Math.floor(leftStrandIndex / 2)];
    }

    function getYarnRef(rowIndex: number, strandIndex: number, patternRepeat: boolean = false): number | undefined {
        if (!state) {
            console.warn("no design to get yarnRef from")
            return undefined;
        }
        if (rowIndex < 0 || strandIndex < 0) return undefined;

        // We reached the first row, so just return the correct yarnRef!
        if (rowIndex === 0) return state.yarnPattern[strandIndex];

        // We have to get the parent's outString
        const isLeftStrand = ((rowIndex % 2) === (strandIndex % 2)) === state.firstStrandIsTieable
        const lookupRow = patternRepeat ? (rowIndex - 1) % state.knots.length : rowIndex - 1
        const parentKnotType = findKnot(lookupRow, strandIndex + (isLeftStrand ? -1 : 0));

        switch (parentKnotType) {
            case KnotType.FF:
            case KnotType.BB:
                return getYarnRef(rowIndex - 1, strandIndex + (isLeftStrand ? -1 : 1), patternRepeat);
            default:
                return getYarnRef(rowIndex - 1, strandIndex, patternRepeat);
        }
    }

    return {
        state,
        findKnot,
        getYarnRef,
        loadDesignFromTemplate(id: string) { dispatch({ type: `loadDesignTemplates`, designId: id }) },
        setYarnRef(index: number, yarnRef: number) { dispatch({ type: `setYarnRef`, index: index, yarnRef: yarnRef }) },
        switchYarnRef(oldYarnRef: number, newYarnRef: number) {
            if (oldYarnRef === newYarnRef) return;
            dispatch({ type: `switchYarnRef`, oldYarnRef: oldYarnRef, newYarnRef: newYarnRef, })
        },
        unshiftYarnPattern(yarnRef: number) { dispatch({ type: `unshiftYarnPattern`, yarnRef: yarnRef, }) },
        pushYarnPattern(yarnRef: number) { dispatch({ type: `pushYarnPattern`, yarnRef: yarnRef }) },
        shiftYarnPattern() { dispatch({ type: `shiftYarnPattern`, }) },
        popYarnPattern() { dispatch({ type: `popYarnPattern` }) },
        setKnot(rowIndex: number, knotIndex: number, knotType: KnotType | undefined) { dispatch({ type: `setKnot`, rowIndex, knotIndex, knotType }) },
        clearEmptyTrailingRows() { dispatch({ type: `clearEmptyTrailingRows` }) }
    }
}

export const DesignContext = createContext<ReturnType<typeof useDesignContext>>({
    state: initialDesignState,
    loadDesignFromTemplate: (id: string) => { },
    getYarnRef: (rowIndex: number, strandIndex: number, patternRepeat?: boolean) => undefined,
    setYarnRef: (index: number, yarnRef: number) => { },
    switchYarnRef: (oldYarnRef: number, newYarnRef: number) => { },
    unshiftYarnPattern: (yarnRef: number) => { },
    pushYarnPattern: (yarnRef: number) => { },
    shiftYarnPattern: () => { },
    popYarnPattern: () => { },
    findKnot: (rowIndex: number, leftStrandIndex: number) => undefined,
    setKnot: (rowIndex: number, leftStrandIndex: number, knot: KnotType | undefined) => { },
    clearEmptyTrailingRows: () => { },
});

export const DesignProvider = ({ children }: { children?: ReactElement | ReactElement[] }): ReactElement => {
    return (
        <DesignContext.Provider value={useDesignContext(initialDesignState)}>
            {children}
        </DesignContext.Provider>
    )
}

// TODO: the below could also be in a separate hook file.
type UseDesign = {
    designTemplates: Design[],
    design: Design;
    loadDesignFromTemplate: (id: string) => void,
    getYarnRef: (rowIndex: number, strandIndex: number, patternRepeat?: boolean) => number | undefined,
    setYarnRef: (index: number, yarnRef: number) => void,
    switchYarnRef: (oldYarnRef: number, newYarnRef: number) => void,
    unshiftYarnPattern: (yarnRef: number) => void,
    pushYarnPattern: (yarnRef: number) => void,
    shiftYarnPattern: () => void,
    popYarnPattern: () => void,
    findKnot: (rowIndex: number, leftStrandIndex: number) => KnotType | undefined,
    setKnot: (rowIndex: number, leftStrandIndex: number, knot: KnotType | undefined) => void,
    clearEmptyTrailingRows: () => void,

}

export const useDesign = (): UseDesign => {
    const { state: design, ...context } = useContext(DesignContext);

    return {
        designTemplates,
        design,
        ...context
    }
}