import { FriendshipBraceletPreview } from "../components";
import { useDesign } from "../contexts/DesignContext";
import { useYarnPacks } from "../contexts/YarnPacksContext";
import { AppFragmentProps } from "./AppFragment";
import '../styles/Header.css';
import { useScrollDirection } from "../contexts/ScrollContext";
import { useLocation } from "react-router-dom";

export default function Header({ className = "appFragment" }: AppFragmentProps) {
    // TODO: should respond to element size or scroll
    const previewLength = 100;

    const { design } = useDesign();
    const { yarnPack } = useYarnPacks();

    const strandsIn = design.yarnPattern.map(ref => yarnPack.yarns[ref % yarnPack.yarns.length])

    let { scrollDirection } = useScrollDirection();
    const location = useLocation();
    const pageHeader = (pathName => {
        switch (pathName) {
            case '/': { return 'Hey there!' }
            case '/motif': { return 'Choose a motif' }
            case '/yarn': { return 'Pick any set of yarns' }
            case '/design': { return 'Make your custom design' }
            case '/weave': { return 'Tie your bracelet like this' }
            case '/shop': { return 'One day we\'ll have a beautiful shop' }
            default: { return 'Besty Bracelets' }
        }
    })(location.pathname)


    return (<>
        <div className={`${className} header`} >
            <div className={`headerTopContainer${scrollDirection === "down" ? "Small" : "Big"}`}>
                <div className="headerTopSubcontainer">
                    <h1>Besty Bracelets</h1>
                </div>
            </div>
            <h2>{pageHeader}</h2>

        </div >
        {yarnPack && design &&
            <div className="previewContainer">
                <FriendshipBraceletPreview
                    strandsIn={strandsIn}
                    knots={design.knots}
                    firstStrandIsTieable={design.firstStrandIsTieable}
                    length={previewLength} />
            </div>
        }
    </>
    )
}