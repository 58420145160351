type Props = {
    className?: string,
    style?: React.CSSProperties,
}

export default function ShopIcon({ className, style }: Props) {
    return (
        <svg viewBox="0 0 490.7 490.7" className={className} style={style}>
            <polygon points="264,0.35 226.6,0.35 217.7,98.35 272.9,98.35 			" />
            <polygon points="343.3,98.35 316.9,0.35 279.9,0.35 288.5,98.35 			" />
            <path d="M245.3,184.65c15.6,0,28.4-12.8,28.4-28.4v-42.4h-56.8v42.4C216.9,171.85,229.8,184.65,245.3,184.65z" />
            <polygon points="431.2,98.35 483.7,98.35 421.5,0.35 386.5,0.35 			" />
            <polygon points="202.1,98.35 210.7,0.35 173.8,0.35 147.3,98.35 			" />
            <path d="M289.2,156.25c0,15.6,12.8,28.4,28.4,28.4s28.4-12.8,28.4-28.4v-42.4h-56.8V156.25z" />
            <polygon points="359.6,98.35 413.7,98.35 369.4,0.35 333.2,0.35 			" />
            <path d="M361.6,156.25c0,15.6,12.8,28.4,28.4,28.4s28.4-12.8,28.4-28.4v-42.4h-56.8V156.25z" />
            <polygon points="104.1,0.35 69.1,0.35 6.9,98.35 59.4,98.35 			" />
            <path d="M433.9,113.95v42.4c0,15.6,12.8,28.4,28.4,28.4s28.4-12.8,28.4-28.4v-42.4H433.9z" />
            <polygon points="76.9,98.35 131,98.35 157.4,0.35 121.3,0.35 			" />
            <path d="M145,156.25c0,15.6,12.8,28.4,28.4,28.4s28.4-12.8,28.4-28.4v-42.4H145L145,156.25L145,156.25z" />
            <path d="M28.7,184.65c15.6,0,28.4-12.8,28.4-28.4v-42.4H0.3v42.4C0.3,171.85,13.1,184.65,28.7,184.65z" />
            <path d="M129,156.45v-42.6H72.6v42.6c0,15.6,12.6,28.2,28.2,28.2l0,0C116.4,184.65,129,172.05,129,156.45z" />
            <path d="M271.7,305.25l25.7-25.7c3.1-3.1,3.1-8.2,0-11.3s-8.2-3.1-11.3,0l-25.3,25.7c-3.1,3.1-3.1,8.2,0,11.3
        C262.4,306.75,266.9,309.55,271.7,305.25z"/>
            <path d="M273.7,349.55c1.6,1.6,6.1,4.3,10.9,0l58.3-57.2c3.1-3.1,3.1-8.2,0-11.3c-3.1-3.1-8.2-3.1-11.3,0l-58.3,57.2
        C270.6,341.45,270.6,346.45,273.7,349.55z"/>
            <path d="M426.1,181.55c-7.8,11.3-21,19.1-36.2,19.1c-14.8,0-28-7.4-36.2-19.1c-7.8,11.3-21,19.1-36.2,19.1
        c-14.8,0-28-7.4-36.2-19.1c-7.8,11.3-21,19.1-36.2,19.1s-28-7.4-36.2-19.1c-7.8,11.3-21,19.1-36.2,19.1
        c-14.8,0-27.6-7.4-35.8-18.3c-15,21-37.7,18.3-37.7,18.3c-14.4,0-27.2-7.4-35-18.3c-8.2,11.3-21,18.3-35.8,18.3
        c-10.9,0-20.6-3.9-28.4-10.1v299.8h35.8v-249.3H196v249.3h294v-300.2c-7.8,6.2-17.5,10.1-28.4,10.1
        C447.1,200.25,433.9,192.85,426.1,181.55z M461.1,398.15H232.9v-157.1h228.3v157.1H461.1z"/>
        </svg>
    )
}