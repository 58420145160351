import yarnPackData from "../data/yarnpacks.json";
import { v4 as uuidV4 } from "uuid";
import YarnPack from "../model/YarnPack";

const yarnPackTemplates: YarnPack[] = [];

function importData() {
    Object.entries(yarnPackData).forEach(([name, yarns]) => {
        const packId: string = uuidV4();
        yarnPackTemplates.push({ id: packId, name: name, yarns: yarns });
    })
}

export function useYarnPackTemplates() {
    if (yarnPackTemplates.length === 0)
        importData();
    return yarnPackTemplates;
}