import designData from "../data/designs.json";
import Design from "../model/Design";
import { KnotType } from "../model/Knot";

let didImport = false;
const designs: Design[] = [];

function importData() {
    for (const template of Object.values(designData)) {
        const validTypes = Object.values(KnotType) as string[];
        let validTemplate: boolean = template.knots.flat().every(knot => {
            if (!(validTypes.includes(knot))) {
                console.warn(`Could not import Design '${template.name}' because it has at least one invalid knot (${knot}).`);
                return false;
            }
            return true;
        });

        if (validTemplate)
            designs.push(new Design(template.name, template.yarnPattern, template.firstStrandIsTieable, template.knots as KnotType[][]))
    }
    didImport = true;
}

// TODO: this would make the templates vulnerable to change. Should deep-copy the design templates.
export function useDesignTemplates(): Design[] {
    if (!didImport)
        importData();
    return designs;
}

export function useExportDesignTemplates() {
    return {
        toJSON: (design: Design) => JSON.stringify(design)
    };
}