import { useLocation, useNavigate } from "react-router-dom"
import DesignIcon from "../components/icons/design";
import HomeIcon from "../components/icons/home";
import MakeIcon from "../components/icons/make";
import PatternsIcon from "../components/icons/patterns";
import ShopIcon from "../components/icons/shop";
import YarnIcon from "../components/icons/yarn";
import "../styles/NavBar.css"
import { AppFragmentProps } from "./AppFragment";

export default function Footer({ className }: AppFragmentProps): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    function isActiveClass(forPath: string): string {
        return location.pathname === forPath ? 'active' : '';
    }

    return (
        <nav className={`${className} navbar`}>
            <button className={isActiveClass('/')} onClick={() => navigate('/')}>
                <HomeIcon />
                Hey there!
            </button>
            <button className={isActiveClass('/motif')} onClick={() => navigate('/motif')}>
                <PatternsIcon />
                Patterns
            </button>
            <button className={isActiveClass('/yarn')} onClick={() => navigate('/yarn')}>
                <YarnIcon />
                Yarn Colors
            </button>
            <button className={isActiveClass('/design')} onClick={() => navigate('/design')}>
                <DesignIcon />
                Design it
            </button>
            <button className={isActiveClass('/weave')} onClick={() => navigate('/weave')}>
                <MakeIcon />
                Make it!
            </button>
            <button className={isActiveClass('/shop')} onClick={() => navigate('/shop')}>
                <ShopIcon />
                Shop
            </button>
        </nav>
    )
}