import { KnotType } from "../model/Knot";
import "../styles/FriendshipBraceletPreview.css"

type Props = {
    strandsIn: string[],
    knots: (KnotType | undefined)[][],
    firstStrandIsTieable: boolean,
    length: number,
    className?: string,
    style?: React.CSSProperties,
}

const FriendshipBraceletPreview = ({ strandsIn, knots, firstStrandIsTieable, length, className, style }: Props) => {
    const strandsOut = [...strandsIn];
    return (
        <div className={`previewRowsContainer ${className}`} style={style}>

            {new Array(length).fill(undefined).map((dummyRowItem, rowIndex) => {
                strandsIn = [...strandsOut];

                return (
                    <div key={rowIndex} className="previewRowItem previewKnotsContainer">
                        {new Array(strandsIn.length).fill(undefined).map((dummyKnotItem, strandIndex) => {
                            const isLeftStrand = (strandIndex % 2) === (rowIndex % 2) === firstStrandIsTieable;
                            const knotIndex = Math.floor((strandIndex - (isLeftStrand ? 0 : 1)) / 2)
                            const knotType = knots[rowIndex % knots.length][knotIndex];

                            // handle right strand
                            if (!isLeftStrand) {
                                const shouldTakeLefttStrand = knotType && [KnotType.FB, KnotType.FF].includes(knotType);
                                const color = strandsIn[strandIndex - (shouldTakeLefttStrand ? 1 : 0)];
                                return <div key={`${rowIndex}_${strandIndex}`}
                                    className="previewKnotItem rightStrand"
                                    style={{ borderBottomColor: color }}
                                />
                            }

                            // switch strands
                            if (knotType && isLeftStrand && [KnotType.BB, KnotType.FF].includes(knotType)) {
                                strandsOut[strandIndex + 1] = strandsIn[strandIndex];
                                strandsOut[strandIndex] = strandsIn[strandIndex + 1];
                            }

                            // handle the left strand
                            const shouldTakeRightStrand = knotType && [KnotType.BB, KnotType.BF].includes(knotType);
                            const color = strandsIn[strandIndex + (shouldTakeRightStrand ? 1 : 0)];
                            return <div key={`${rowIndex}_${strandIndex}`}
                                className="previewKnotItem leftStrand"
                                style={{ borderTopColor: color }} />
                        })}
                    </div>
                )
            })}
        </div >
    )
}

export default FriendshipBraceletPreview