import { v4 as uuidV4 } from "uuid";
import { KnotType } from "./Knot";

// TODO: move this to context, as part of the DesignContext.

export interface IDesign {
    id: string,
    name: string,
    yarnPattern: number[],
    firstStrandIsTieable: boolean,
    knots: (undefined | KnotType)[][],
}

export default class Design implements IDesign {
    public id: string;
    constructor(
        public name: string,
        public yarnPattern: number[],
        public firstStrandIsTieable: boolean,
        public knots: (undefined | KnotType)[][],
    ) {
        this.id = uuidV4()
    }
}