import "./styles/App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom"
import { DesignProvider, YarnPacksProvider, ScrollProvider } from "./contexts";
import { Header, Footer, MotifPage, YarnPage, HomePage, DesignPage, WeavePage, ShopPage } from "./routes"


export default function App() {
    return (
        <DesignProvider>
            <YarnPacksProvider>
                <div className="appFragmentContainer">
                    <ScrollProvider>
                        <BrowserRouter>
                            <Header className={`headerFragment`} />
                            <Routes>
                                <Route path="/" element={<HomePage className={`mainFragment`} />} />
                                <Route path="/motif" element={<MotifPage className={`mainFragment`} />} />
                                <Route path="/yarn" element={<YarnPage className={`mainFragment`} />} />
                                <Route path="/design" element={<DesignPage className={`mainFragment`} />} />
                                <Route path="/weave" element={<WeavePage className={`mainFragment`} />} />
                                <Route path="/shop" element={<ShopPage className={`mainFragment`} />} />
                            </Routes>
                            <Footer className={`footerFragment`} />
                        </BrowserRouter>
                    </ScrollProvider>
                </div>
            </YarnPacksProvider>
        </DesignProvider>
    )
}