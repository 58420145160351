import { useEffect, useRef } from 'react';
import { FriendshipBraceletPreview } from '../components';
import ButtonGrid from '../components/ButtonGrid';
import { useDesign } from '../contexts/DesignContext';
import { useScrollDirection } from '../contexts/ScrollContext';
import { useYarnPacks } from '../contexts/YarnPacksContext';
import Design from '../model/Design';
import { AppFragmentProps } from './AppFragment'

export default function MotifPage({ className }: AppFragmentProps) {
    const { designTemplates, design, loadDesignFromTemplate } = useDesign();
    const { yarnPack } = useYarnPacks();


    const scrollRef = useRef<HTMLDivElement>(null);
    const { setScrollRef } = useScrollDirection();

    useEffect(() => {
        setScrollRef(scrollRef);

    }, [scrollRef, setScrollRef])


    return (
        <div className={className} ref={scrollRef}>

            <h1> this is a test</h1>
            <ButtonGrid<Design>
                items={designTemplates}
                activeItem={designTemplates.find(t => t.id === design.id)}
                onClick={d => loadDesignFromTemplate(d.id)}

            >
                {(d: Design) => <FriendshipBraceletPreview className="croppedToButton"
                    strandsIn={d.yarnPattern.map(yarnRef => yarnPack.yarns[yarnRef % yarnPack.yarns.length])}
                    knots={d.knots}
                    firstStrandIsTieable={d.firstStrandIsTieable}
                    length={20} />}
            </ButtonGrid>
        </div>
    )
}