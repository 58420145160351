type Props = {
    className?: string,
    style?: React.CSSProperties,
}

export default function PatternsIcon({ className, style }: Props) {
    return (
        <svg viewBox="0 0 511.999 511.999" className={className} style={style}>

            <path d="M507.101,4.898c-6.53-6.531-17.118-6.531-23.649,0l-45.563,45.563l-41.534-13.844
    c-18.144-6.047-37.816-1.405-51.338,12.119C334.839,58.915,53.146,340.607,43.16,350.593
    c-13.522,13.523-18.165,33.193-12.118,51.337l13.845,41.534L4.898,483.452c-6.531,6.53-6.531,17.118,0,23.649
    c6.531,6.531,17.118,6.531,23.649,0l39.991-39.991c44.756,14.913,47.016,16.448,57.517,16.448
    c13.054,0,25.757-5.126,35.358-14.727c10.116-10.116,291.178-291.178,301.851-301.851c13.524-13.524,18.167-33.195,12.119-51.338
    L461.538,74.11l45.563-45.563C513.632,22.017,513.632,11.429,507.101,4.898z M137.763,445.182
    c-4.506,4.507-11.064,6.055-17.112,4.04L77.24,434.756l-14.469-43.404c-2.016-6.049-0.468-12.608,4.039-17.115l27.785-27.785
    v54.225c0,9.235,7.487,16.722,16.722,16.722h54.225L137.763,445.182z M189.192,383.958H128.04v-61.153h61.153V383.958z
     M189.192,289.362H151.69l37.503-37.503V289.362z M222.637,360.308v-37.503h37.503L222.637,360.308z M283.789,289.362h-61.152
    V228.21h61.152V289.362z M283.789,194.765h-37.502l37.502-37.502V194.765z M317.234,265.713V228.21h37.503L317.234,265.713z
     M378.384,194.765h-61.152v-61.152h61.152V194.765z M439.614,143.332l-27.785,27.785v-54.225c0-9.235-7.487-16.722-16.722-16.722
    h-54.224l27.785-27.785c4.506-4.508,11.062-6.056,17.111-4.039l43.407,14.468l14.468,43.407
    C445.669,132.267,444.121,138.823,439.614,143.332z"/>
        </svg>
    )
}