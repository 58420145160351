type Props = {
    className?: string,
    style?: React.CSSProperties,
}

export default function MakeIcon({ className, style }: Props) {
    return (
        <svg viewBox='.5 .5 31 31' className={className} style={{ ...style, strokeWidth: .6 }}>
            <line x1="3.5" y1="4" x2="3.5" y2="32" stroke="black" />
            <line x1="4" y1="4" x2="16" y2="28" stroke="black" />
            <line x1="12" y1="4" x2="24" y2="28" stroke="black" />
            <line x1="20" y1="4" x2="28" y2="20" stroke="black" />
            <line x1="4" y1="20" x2="8" y2="28" stroke="black" />

            <line x1="12" y1="4" x2="4" y2="20" stroke="black" />
            <line x1="20" y1="4" x2="8" y2="28" stroke="black" />
            <line x1="28" y1="4" x2="16" y2="28" stroke="black" />
            <line x1="28" y1="20" x2="24" y2="28" stroke="black" />
            <line x1="28.75" y1="4" x2="28.75" y2="32" stroke="black" />

            <circle cx="4" cy="4" r="3" stroke="black" fill="#CAFFBF" />
            <circle cx="12" cy="4" r="3" stroke="black" fill="#A0C4FF" />
            <circle cx="20" cy="4" r="3" stroke="black" fill="#FFC6FF" />
            <circle cx="28" cy="4" r="3" stroke="black" fill="#FFD6A5" />

            <circle cx="8" cy="12" r="3" stroke="black" fill="#CAFFBF" />
            <circle cx="16" cy="12" r="3" stroke="black" fill="#A0C4FF" />
            <circle cx="24" cy="12" r="3" stroke="black" fill="#FFC6FF" />

            <circle cx="4" cy="20" r="3" stroke="black" fill="#FFD6A5" />
            <circle cx="12" cy="20" r="3" stroke="black" fill="#CAFFBF" />
            <circle cx="20" cy="20" r="3" stroke="black" fill="#A0C4FF" />
            <circle cx="28" cy="20" r="3" stroke="black" fill="#FFC6FF" />

            <circle cx="8" cy="28" r="3" stroke="black" fill="#FFD6A5" />
            <circle cx="16" cy="28" r="3" stroke="black" fill="#CAFFBF" />
            <circle cx="24" cy="28" r="3" stroke="black" fill="#A0C4FF" />
        </svg>
    )
}