import { ReactNode } from "react"
import "../styles/ButtonGrid.css"

type Props<T> = {
    items: T[],
    activeItem?: T,
    onClick?: (e: T) => void,
    gridClassName?: string,
    itemClassName?: string,
    children?: ReactNode | ((item: T) => ReactNode),
}

export default function ButtonGrid<T>({ items, activeItem, onClick, gridClassName = '', itemClassName = '', children }: Props<T>) {

    return (
        <div className={`buttonGrid ${gridClassName}`}>
            {items.map((item, index) => (
                <button key={index}
                    className={`buttonGridItem${item === activeItem ? 'Selected' : ''} ${itemClassName}`}
                    onClick={onClick && (() => onClick(item))}>
                    {children ? typeof children === "function" ? children(item) : children : ""}
                </button>
            ))}
        </div>
    )
}