type Props = {
    className?: string,
    style?: React.CSSProperties,
}

export default function home({ className, style }: Props) {
    return (
        <svg viewBox="0 0 32 32" className={className} style={style}>
            <path id="hand" stroke="none"
                d="M 12 28 
                L4 16 
                C2.5 13.75, 5.5 11.75, 7 14 
                L11 20
                C11.2225 20.34, 11.5625 20.1175, 11.34 19.7725
                L5.84 11.5225
                C4.34 9.2725, 7.34 7.2725, 8.84 9.5225
                L14.29 17.7725
                C14.5125 18.1125, 14.8525 17.89, 14.63 17.545
                L7.68 7.045
                
                
                C6.18 4.795, 9.18 2.795, 10.68 5.045
                L17.58 15.545
                C17.7625 15.885, 18.1025 15.6625, 17.88 15.3175
                
                L11.2346 5.205
                C9.7346 2.995, 12.7346 0.995, 14.2346 3.245
                
                L21.2346 13.745
                C21.4117 14.085, 21.7517 14.085, 21.7517 13.745
                
                L21.7517 8
                
                C21.7517 4, 26.7517 4, 26.7517 8
                
                L26.7517 22.2517
                C26.7517 32, 14.6666666667 32, 12 28
                " />
        </svg>
    )
}