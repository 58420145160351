import React, { createContext, ReactElement, useContext, useEffect, useState } from "react";

type ScrollContextState = {
    ref?: React.RefObject<HTMLElement>,
    direction: ScrollDirection,
}
type ScrollDirection = "down" | "up" | undefined;
const initialScrollContextState: ScrollContextState = { ref: undefined, direction: undefined };

export function useScrollContext(initialState: ScrollContextState) {
    const [scrollRef, setScrollRef] = useState<React.RefObject<HTMLElement> | undefined>(initialState.ref);
    const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(initialState.direction);

    useEffect(() => {
        const currentRef = scrollRef?.current;
        if (!currentRef) {
            setScrollDirection(undefined);
            return;
        }

        let lastScrollTop = Math.round(currentRef.scrollTop);
        let lastScrollBottom = Math.round(currentRef.scrollHeight - currentRef.scrollTop - currentRef.clientHeight);

        function updateScrollDirection(this: HTMLElement, event: Event) {
            const scrollTop = Math.round(this.scrollTop);
            const scrollBottom = Math.round(this.scrollHeight - this.scrollTop - this.clientHeight);

            const scrollTopDirection: ScrollDirection = scrollTop > lastScrollTop ? "down" : (scrollTop < lastScrollTop ? "up" : undefined);
            const scrollBottomDirection: ScrollDirection = scrollBottom < lastScrollBottom ? "down" : (scrollBottom > lastScrollBottom ? "up" : undefined);

            if (scrollTopDirection === scrollBottomDirection) {
                setScrollDirection((prev) => Math.abs(scrollTop - lastScrollTop) > 10 || scrollTop < 10 ? scrollTopDirection : prev);
            }

            lastScrollTop = Math.max(scrollTop, 0);
            lastScrollBottom = Math.max(scrollBottom, 0);
        };

        currentRef.addEventListener("scroll", updateScrollDirection);
        return () => {
            currentRef.removeEventListener("scroll", updateScrollDirection);
        }
    }, [scrollDirection, scrollRef]);

    return { setScrollRef, scrollDirection }
}

export const ScrollContext = createContext<ReturnType<typeof useScrollContext>>({
    setScrollRef: () => { },
    scrollDirection: undefined,
})

export const ScrollProvider = ({ children }: { children?: ReactElement | ReactElement[] }): ReactElement => {
    return (
        <ScrollContext.Provider value={useScrollContext(initialScrollContextState)}>
            {children}
        </ScrollContext.Provider>
    )
}

type UseScrollDirection = {
    scrollDirection: ScrollDirection,
    setScrollRef: (ref: React.RefObject<HTMLElement> | undefined) => void,
}

export const useScrollDirection = (): UseScrollDirection => {
    const { ...context } = useContext(ScrollContext);
    return { ...context };
}