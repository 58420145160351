import "../styles/Design.css";
import "../styles/Knot.css";
import { useDesign } from "../contexts/DesignContext";
import { AppFragmentProps } from "./AppFragment";
import { useYarnPacks } from "../contexts/YarnPacksContext";
import { Fragment } from "react";
import { KnotType, nextKnotType } from "../model/Knot";

const strandPoints = {
    "switch": { left: "0 -50 50 50 100 150", right: "100 -50 50 50 0 150" },
    "return": { left: "0 -50 50 50 0 150", right: "100 -50 50 50 100 150" },
    "loose": { left: `25 0 25 100`, right: "75 0 75 100" },
    // squigly paths:
    // left: `0 -50 30 10 25 30 30 50 25 70 30 90 0 150 `,
    // right: "100 -50 70 10 75 30 70 50 75 70 70 90 100 150"
}

function getStrandPoints(knotType: KnotType | undefined, isLeft: boolean) {
    const type = knotType ? [KnotType.BB, KnotType.FF].includes(knotType) ? "switch" : "return" : "loose";
    return isLeft ? strandPoints[type].left : strandPoints[type].right;
}

const arrowPaths = {
    [KnotType.FF]: `M40 30 L60 70 M45 65 L60 70 65 55`,
    [KnotType.FB]: `M40 30 L50 50 40 70 M35 55 L40 70 55 65`,
    [KnotType.BF]: `M60 30 L50 50 60 70 M45 65 L60 70 65 55`,
    [KnotType.BB]: `M60 30 L40 70 M35 55 L40 70 55 65`,
}

export default function DesignPage({ className }: AppFragmentProps) {
    const { design, shiftYarnPattern, popYarnPattern, findKnot, getYarnRef, unshiftYarnPattern, pushYarnPattern, clearEmptyTrailingRows, setKnot } = useDesign();
    const { yarnPack } = useYarnPacks();

    const handleShiftStrandButtonClick = () => {
        shiftYarnPattern();
    }

    const handlePopStrandButtonClick = () => {
        popYarnPattern();
    }

    function handleKnotClick(rowIndex: number, leftStrandIndex: number) {
        // some guard clauses
        if (!design) throw new Error("Knot clicked, but no design is loaded.");
        if (rowIndex < 0 || leftStrandIndex < -1 || leftStrandIndex > design.yarnPattern.length)
            throw new Error(`Cannot place a knot at coordinates ${rowIndex}, ${leftStrandIndex}`)

        if (rowIndex > 0)
            // shouldn't tie a knot if both parents aren't tied.
            if (!findKnot(rowIndex - 1, leftStrandIndex - 1) && !findKnot(rowIndex - 1, leftStrandIndex + 1)) return;

        /* 
        * check if a strand should be added
        */
        if (leftStrandIndex === -1) {
            // add new strand on the left side
            // get the yarn from pack, left of the yarn that the leftmost strand uses.
            let newYarnRef = design.yarnPattern[0] - 1;
            // if that leads to -1, then get the rightmost yarn from the pack. But if no yarnpack is loaded, get the highest yarnRef in the design and add one.
            if (newYarnRef < 0) newYarnRef = yarnPack ? yarnPack.yarns.length - 1 : design.yarnPattern.reduce((prev, curr) => Math.max(prev, curr), 0) + 1;
            // add the yarnRef to the design's yarnPattern. That function will automatically move all knots over by 1.
            unshiftYarnPattern(newYarnRef)
            // now that strands and knots are moved over, we should update the event's data.
            leftStrandIndex++;
            console.log("unshifted")
        } else if (leftStrandIndex === design.yarnPattern.length - 1) {
            // add new strand on the right side
            // get the yarn from pack, right of the yarn that the rightmost strand uses.
            let newYarnRef = design.yarnPattern[design.yarnPattern.length - 1] + 1;
            if (yarnPack) newYarnRef %= yarnPack.yarns.length;
            pushYarnPattern(newYarnRef)
            console.log("pushed")
        }

        /*
         * calculate the correct new knot type
         */
        const prevType = findKnot(rowIndex, leftStrandIndex);
        let nextType = nextKnotType(prevType);
        if (!nextType && design.knots.length > rowIndex + 1) {
            // nextType===undefined means we're untieing the knot. But we don't want to orphan any child Knots
            if (
                (findKnot(rowIndex + 1, leftStrandIndex - 1) && !findKnot(rowIndex, leftStrandIndex - 1)) || // if true, there is a left child knot, but no left partner, so untieing this knot would orphan the left child
                (findKnot(rowIndex + 1, leftStrandIndex + 1) && !findKnot(rowIndex, leftStrandIndex + 2)) // if true, there is a right child knot, but no right partner, so untieing this knot would orphan the right child
            ) nextType = nextKnotType(undefined); // this will return the first knot type.
        }

        /*
         * set the knot
         */
        setKnot(rowIndex, Math.floor(leftStrandIndex / 2), nextType);

        /*
         * check if we now created empty rows
         */
        clearEmptyTrailingRows();
    }

    return (
        <div className={`${className}`}>
            <div className={`designGridContainer`}>
                <button
                    className="designGridItem"
                    onClick={handleShiftStrandButtonClick}>
                    &#x2704;
                </button>
                {design.yarnPattern.map((yarnRef, index) => (
                    <button key={index}
                        className={`designGridItem`}
                        style={{
                            backgroundColor: yarnPack.yarns[yarnRef % yarnPack.yarns.length],
                        }}
                    // onClick={(e) => { handleStrandClick(e, index) }}
                    >{yarnRef}
                    </button>
                ))}
                <button
                    className="designGridItem"
                    onClick={handlePopStrandButtonClick}
                    style={{ transform: "rotate(180deg)" }}>
                    &#x2704;
                </button>

                {(new Array((design.knots.length + 1) * (design.yarnPattern.length + 1))).fill(undefined).map((dummyItem, index) => {
                    // TODO: Should this be a separate component?

                    // grid coordinates are not indexed, so a 1 has to be added
                    const gridRow = Math.floor(index / (design.yarnPattern.length + 1)) + 2;
                    const gridColumn = (index % (design.yarnPattern.length + 1)) + 1;

                    // translates to which knot coordinates?
                    const rowIndex = gridRow - 2;
                    const strandIndex = gridColumn - 2;

                    // knots are stored in context on their leftStrandIndex, so let's check if we're on a left strand
                    // 1. check if we're on an even row (rowindex %2===0)
                    // 2. if we're on an even row and the firstStrandIs tieable (===design.firstStrandIsTieable)
                    // 3. then even strand numbers are left strand indexes (===(strandIndex%2===0))
                    const isLeftStrandIndex = (rowIndex % 2) === 0 === design.firstStrandIsTieable === (strandIndex % 2 === 0)
                    // only do stuff on the leftStrandIndex
                    if (!isLeftStrandIndex) return <Fragment key={index} />;

                    const knotType = findKnot(rowIndex, strandIndex);
                    const leftYarnRef = getYarnRef(rowIndex, strandIndex);
                    const rightYarnRef = getYarnRef(rowIndex, strandIndex + 1);
                    const leftColor = leftYarnRef !== undefined ? yarnPack.yarns[leftYarnRef % yarnPack.yarns.length] : undefined;
                    const rightColor = rightYarnRef !== undefined ? yarnPack?.yarns[rightYarnRef % yarnPack.yarns.length] : undefined;
                    const knotColor = knotType && ((knotType === KnotType.FF || knotType === KnotType.FB) ? leftColor : rightColor);

                    return (
                        <svg key={index} className="designGridKnot" viewBox="0 0 100 100"
                            style={{
                                // outline: "solid 1px blue",
                                gridColumn: `${gridColumn} / span 2`,
                                gridRow: `${gridRow}`,
                            }}
                            onClick={() => handleKnotClick(rowIndex, strandIndex)}
                        >
                            {leftColor && <>
                                <polyline
                                    className='strandBorder'
                                    points={getStrandPoints(knotType, true)} />
                                <polyline
                                    className='strandFill'
                                    points={getStrandPoints(knotType, true)}
                                    stroke={leftColor} />
                            </>}
                            {rightColor && <>
                                <polyline
                                    className='strandBorder'
                                    points={getStrandPoints(knotType, false)} />
                                <polyline
                                    points={getStrandPoints(knotType, false)}
                                    className='strandFill'
                                    stroke={rightColor} />
                            </>}

                            {knotColor && <>
                                <ellipse id="knotEllipse" cx="50" cy="50" rx="40" ry="40" fill={knotColor} />
                                <path className="arrowBorder" d={arrowPaths[knotType]} />
                                <path className="arrowFill" d={arrowPaths[knotType]} />
                            </>}
                        </svg>
                    )
                })}
            </div >
        </div >
    );
}